import styled from 'styled-components'
import Close from '../../../../static/steam-img/Close.svg'

export const SearchListing = styled.div`
  overflow-y: auto;
  overflow-x: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 560px;
  background: #fff;
  height: 100vh;
  right: -560px;
  transition: 1s ease-in-out;
  z-index: 10;
  &.visible {
    transition: 1s ease-in-out;
    right: 0;
  }

  .gcs__searchPagination {
    display: none;
    &--active {
      display: block;
    }
  }

  //OVERRIDE PAGINATION STYLING
  .pagination__numberedButtonContainer {
    display: flex;
    .pagination__previousButton {
      background-image: url('https://www.uipath.com/hubfs/UiPath%20Resources/Assets/Pagination_Arrows_Left_White.svg');
      &:hover {
        text-decoration: none;
      }
    }

    .pagination__nextButton {
      margin-left: auto;
      background-image: url('https://www.uipath.com/hubfs/UiPath%20Resources/Assets/Pagination_Arrows_Right_White.svg');
      &:hover {
        text-decoration: none;
      }
    }

    .pagination__nextButton,
    .pagination__previousButton {
      padding: 2px 24px;
      width: 106px;
      box-sizing: border-box;
      font-size: 16px;
      background-color: #0067df;
      color: #fff;
      line-height: 32px;
    }
  }

  #gcs__Close {
    position: absolute;
    top: 0;
    right: 24px;
    opacity: 0.5;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    line-height: 1.8;
    color: #4e5758;
  }

  .gcs__searchContainer {
    margin: 40px 24px;
  }

  .gcs__searchInputContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .gcs__searchInputTextBoxContainer {
    position: relative;
    margin-bottom: 16px;
    width: 100%;
  }

  #gcs__input {
    padding: 0 16px;
    width: 100%;
    height: 40px;
    color: #131d40;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    outline: none;
    box-sizing: border-box;
  }

  .gcs__searchBtn {
    flex: 1 0 auto;
    padding: 8px 20px;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    background-color: #0067df;
    color: #fff;
  }

  .gcs__resultsContainer {
    margin: 8px 0;
    transition: all 0.5s ease;
    visibility: hidden;
    opacity: 0;
  }

  .gcs__resultsContainer.gcs__resultsContainer--active {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .gcs__searchItem {
    margin: 16px 0;
    cursor: pointer;
  }

  form {
    position: relative;
  }

  .gcs__clearInput {
    background-image: url(${Close});
    width: 10px;
    height: 10px;
    top: 50%;
    display: none;
    transform: translateY(-50%);
    position: absolute;
    right: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .gcs__clearInput--active {
    display: block;
  }

  .gcs__tabsContainer {
    padding: 16px 0 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in;
  }

  .gcs__tabsContainer.gcs__tabsContainer--active {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-out;
  }

  .gcs__tab {
    padding: 8px;
    width: 25%;
    border-bottom: 3px solid transparent;
    color: #0067df;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.4;
    text-align: center;
    cursor: pointer;
  }

  .gcs__tab:hover {
    border-bottom: 3px solid #0067df;
  }

  .gcs__tab.gcs__tab--active {
    border-bottom: 3px solid #0067df;
  }

  .gcs__searchItemTitle {
    margin: 0;
    color: #0067df;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: normal;
  }

  .gcs__searchItemTitle b {
    background-color: #f6fcfe;
    color: #0067df;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  }

  .gcs__searchItemTitle:hover {
    text-decoration: underline;
  }

  .gcs__searchItemLinkContainer {
    width: 100%;
  }

  .gcs__searchItemLink {
    display: block;
    width: 100%;

    color: #0067df;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .gcs__searchItemLink:hover {
    text-decoration: underline;
  }

  .gcs__searchItemDescription {
    br {
      display: none;
    }
    color: #131d40;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    margin: 5px 0;
  }

  .gcs__searchItemDescription b {
    background-color: #f6fcfe;
    color: #131d40;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  }

  .gcs__searchItemDescription:hover {
    color: #131d40;
  }

  .gcs__searchItemLabelContainer {
    text-align: left;
  }

  .gcs__searchItemLabel {
    color: #0067df;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.4;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #eaeef5;
    margin-top: 0;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 5px;
  }

  @media screen and (max-width: 560px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .gcs__searchInputTextBoxContainer {
      margin-right: 16px;
      margin-bottom: 0;
      width: 75%;
    }
  }
`
