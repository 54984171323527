const lineHeightVals = {
  1: '16px',
  2: '20px',
  3: '24px',
  4: '28px',
  5: '32px',
  6: '36px',
  7: '40px',
  8: '44px',
  9: '48px',
  10: '56px',
  11: '64px',
  12: '72px',
  13: '80px',
  14: '88px',
}

const fontSizeVals = {
  0: '10px',
  1: '12px',
  2: '14px',
  3: '16px',
  4: '18px',
  5: '20px',
  6: '22px',
  7: '24px',
  8: '26px',
  9: '28px',
  10: '32px',
  11: '36px',
  12: '40px',
  13: '42px',
  14: '44px',
  15: '48px',
  16: '56px',
  17: '64px',
  18: '80px',
  19: '96px',
}

const fontWeightVals = {
  extralight: '200',
  thin: '100',
  regular: '400',
  semibold: '600',
  bold: '700',
}

const letterSpacingVals = {
  default: '0px',
  wide: '1px',
  narrower: '-1px',
  narrow: '-0.5px',
  veryNarrow: '-2px',
}
const paragraphSpacingVals = {
  0: '0px',
}

const typography = {
  fontFamilyName: 'Inter',
  lineHeights: lineHeightVals,
  fontSizes: fontSizeVals,
  letterSpacing: letterSpacingVals,
  fontWeights: fontWeightVals,
  paragraphSpacing: paragraphSpacingVals,
  typography: {
    displayXL: {
      lg: {
        fontWeight: fontWeightVals.thin,
        lineHeight: lineHeightVals[14],
        fontSize: fontSizeVals[19],
        letterSpacing: letterSpacingVals.veryNarrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.thin,
        lineHeight: lineHeightVals[14],
        fontSize: fontSizeVals[18],
        letterSpacing: letterSpacingVals.veryNarrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.extralight,
        lineHeight: lineHeightVals[9],
        fontSize: fontSizeVals[13],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    display: {
      lg: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[12],
        fontSize: fontSizeVals[17],
        letterSpacing: letterSpacingVals.veryNarrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[11],
        fontSize: fontSizeVals[16],
        letterSpacing: letterSpacingVals.veryNarrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[8],
        fontSize: fontSizeVals[11],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    heading1: {
      lg: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[11],
        fontSize: fontSizeVals[16],
        letterSpacing: letterSpacingVals.veryNarrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[9],
        fontSize: fontSizeVals[15],
        letterSpacing: letterSpacingVals.veryNarrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[7],
        fontSize: fontSizeVals[10],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    heading2: {
      lg: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[9],
        fontSize: fontSizeVals[14],
        letterSpacing: letterSpacingVals.veryNarrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[8],
        fontSize: fontSizeVals[12],
        letterSpacing: letterSpacingVals.veryNarrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[6],
        fontSize: fontSizeVals[9],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    heading3: {
      lg: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[8],
        fontSize: fontSizeVals[11],
        letterSpacing: letterSpacingVals.veryNarrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[6],
        fontSize: fontSizeVals[10],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[5],
        fontSize: fontSizeVals[8],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    heading4: {
      lg: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[5],
        fontSize: fontSizeVals[9],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[4],
        fontSize: fontSizeVals[7],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[4],
        fontSize: fontSizeVals[6],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    heading5: {
      lg: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[5],
        fontSize: fontSizeVals[7],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[5],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[5],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    heading6: {
      lg: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[5],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[2],
        fontSize: fontSizeVals[4],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[4],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    leadXL: {
      lg: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[6],
        fontSize: fontSizeVals[7],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[4],
        fontSize: fontSizeVals[5],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[4],
        fontSize: fontSizeVals[5],
        letterSpacing: letterSpacingVals.narrower,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    lead: {
      lg: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[6],
        fontSize: fontSizeVals[7],
        letterSpacing: letterSpacingVals.narrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[4],
        fontSize: fontSizeVals[5],
        letterSpacing: letterSpacingVals.narrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[4],
        fontSize: fontSizeVals[5],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    leadXS: {
      lg: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[5],
        fontSize: fontSizeVals[5],
        letterSpacing: letterSpacingVals.narrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[4],
        letterSpacing: letterSpacingVals.narrow,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[4],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    labelAlt: {
      lg: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[2],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[2],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[2],
        fontSize: fontSizeVals[2],
        letterSpacing: letterSpacingVals.wide,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    label: {
      lg: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[2],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    bodyAlt: {
      lg: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    body: {
      lg: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[3],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    bodyXS: {
      lg: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[2],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[2],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[3],
        fontSize: fontSizeVals[2],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    captionAlt: {
      lg: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[1],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[1],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.semibold,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[1],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    caption: {
      lg: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[1],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[1],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[1],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    microALT: {
      lg: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[0],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[0],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.bold,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[0],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
    micro: {
      lg: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[0],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      md: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[0],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
      sm: {
        fontWeight: fontWeightVals.regular,
        lineHeight: lineHeightVals[1],
        fontSize: fontSizeVals[0],
        letterSpacing: letterSpacingVals.default,
        paragraphSpacing: paragraphSpacingVals[0],
      },
    },
  },
}

export default typography
