export const LocaleCodes: any = {
  ENGLISH: {
    name: 'U.S. English',
    code: 'en-US',
  },
  FRENCH: {
    name: 'French',
    code: 'fr-FR',
  },
  GERMAN: {
    name: 'German',
    code: 'de-DE',
  },
  JAPANESE: {
    name: 'Japanese',
    code: 'ja-JP',
  },
  KOREAN: {
    name: 'Korean',
    code: 'ko-KR',
  },
  PORTUGUESE: {
    name: 'Portuguese',
    code: 'pt-BR',
  },
  SPANISH: {
    name: 'Spanish',
    code: 'es-MX',
  },
}
