import { useStaticQuery, graphql } from 'gatsby'

const allContentfulNavMenu = () => {
  return useStaticQuery(graphql`
    {
      allContentfulNavMenu(
        filter: {
          location: {
            in: [
              "Footer"
              "French Footer"
              "German Footer"
              "Japanese Footer"
              "Portuguese Footer"
              "Korean Footer"
              "Spanish Footer"
            ]
          }
        }
      ) {
        nodes {
          name
          location
          entryLocale
          node_locale
          socialMediaFooterOnly {
            ... on ContentfulMicrocopySet {
              key
              values {
                key
                value
              }
            }
          }
          legalFooterOnly {
            ... on ContentfulTopicCta {
              text
              id
              name
              externalUrl
              description
              internalLink {
                __typename
                ... on ContentfulPageProduct {
                  id
                  name
                  slug
                }
                ... on ContentfulPageFlex {
                  id
                  name
                  slug
                }
              }
            }
          }
          menuGroups {
            ... on ContentfulMenuGroup {
              groupName
              parentLink {
                __typename
                ... on ContentfulTopicCta {
                  text
                  id
                  name
                  externalUrl
                  internalLink {
                    __typename
                    ... on ContentfulPageProduct {
                      id
                      name
                      slug
                    }
                    ... on ContentfulPageFlex {
                      id
                      name
                      slug
                    }
                  }
                }
              }
              pages {
                __typename
                ... on ContentfulTopicCta {
                  text
                  id
                  name
                  externalUrl
                  description
                  internalLink {
                    __typename
                    ... on ContentfulPageProduct {
                      id
                      name
                      slug
                    }
                    ... on ContentfulPageFlex {
                      id
                      name
                      slug
                    }
                  }
                }
                ... on ContentfulMenuGroup {
                  groupName
                  parentLink {
                    __typename
                    ... on ContentfulTopicCta {
                      text
                      id
                      name
                      externalUrl
                      description
                      internalLink {
                        __typename
                        ... on ContentfulPageProduct {
                          id
                          name
                          slug
                        }
                        ... on ContentfulPageFlex {
                          id
                          name
                          slug
                        }
                      }
                    }
                  }
                  pages {
                    __typename
                    ... on ContentfulMenuGroup {
                      id
                      name
                    }
                    ... on ContentfulTopicCta {
                      text
                      id
                      name
                      externalUrl
                      description
                      internalLink {
                        __typename
                        ... on ContentfulPageProduct {
                          id
                          name
                          slug
                        }
                        ... on ContentfulPageFlex {
                          id
                          name
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
}

export default { allContentfulNavMenu }
