import { useStaticQuery, graphql } from 'gatsby'

const allContentfulNavMenu = () => {
  return useStaticQuery(graphql`
    {
      allContentfulNavMenu(
        filter: {
          location: {
            in: [
              "Header"
              "French Header"
              "German Header"
              "Japanese Header"
              "Portuguese Header"
              "Korean Header"
              "Spanish Header"
            ]
          }
        }
      ) {
        nodes {
          name
          location
          entryLocale
          node_locale
          primaryCta {
            ... on ContentfulTopicCta {
              id
              text
              externalUrl
              internalLink {
                __typename
                ... on ContentfulPageProduct {
                  id
                  name
                  slug
                }
                ... on ContentfulPageFlex {
                  id
                  name
                  slug
                }
              }
            }
          }
          topHatLinks {
            ... on ContentfulTopicCta {
              id
              text
              externalUrl
              internalLink {
                __typename
                ... on ContentfulPageProduct {
                  id
                  name
                  slug
                }
                ... on ContentfulPageFlex {
                  id
                  name
                  slug
                }
              }
            }
          }
          menuGroups {
            ... on ContentfulMenuGroup {
              groupName
              parentLink {
                __typename
                ... on ContentfulTopicCta {
                  id
                  name
                  description
                  externalUrl
                  internalLink {
                    __typename
                    ... on ContentfulPageProduct {
                      id
                      name
                      slug
                    }
                    ... on ContentfulPageFlex {
                      id
                      name
                      slug
                    }
                  }
                }
              }
              pages {
                __typename
                ... on ContentfulTopicCta {
                  text
                  id
                  name
                  externalUrl
                  description
                  internalLink {
                    __typename
                    ... on ContentfulPageProduct {
                      id
                      name
                      slug
                    }
                    ... on ContentfulPageFlex {
                      id
                      name
                      slug
                    }
                  }
                }
                ... on ContentfulMenuGroup {
                  groupName
                  parentLink {
                    __typename
                    ... on ContentfulTopicCta {
                      text
                      id
                      name
                      description
                      externalUrl
                      internalLink {
                        __typename
                        ... on ContentfulPageProduct {
                          id
                          name
                          slug
                        }
                        ... on ContentfulPageFlex {
                          id
                          name
                          slug
                        }
                      }
                    }
                  }
                  pages {
                    __typename
                    ... on ContentfulTopicCta {
                      text
                      id
                      name
                      externalUrl
                      description
                      internalLink {
                        __typename
                        ... on ContentfulPageProduct {
                          id
                          name
                          slug
                        }
                        ... on ContentfulPageFlex {
                          id
                          name
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
}

export default { allContentfulNavMenu }
