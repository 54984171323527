// Libraries
import React from 'react'
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core'
import { ThemeProvider } from 'styled-components'

// Images
import favicon from '../../../static/steam-img/favicon.ico'

// Components
import Helmet from 'react-helmet'
import Footer from '../global/footer'
import Header from '../global/header'
import MinHeader from '../global/minHeader'

// Data
import footerJSON from './footer.json'

// Styles
import { GlobalStyle } from '../../styles/global'
import palette from '../../styles/palette'
import typography from '../../styles/typography'

const customizedTheme = createMuiTheme({
  webMktPalette: palette,
  webMktTypography: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 769,
      md: 1025,
      lg: 1281,
      xl: 1600,
    },
  },
})
class Template extends React.Component {
  render() {
    const {
      children,
      lang,
      hideNormalLayout,
      minimalHeader,
      minimalFooter,
    } = this.props
    return (
      <div data-cy="Layout">
        <MuiThemeProvider theme={customizedTheme}>
          <ThemeProvider theme={customizedTheme}>
            <GlobalStyle />
            <Helmet>
              <link rel="icon" href={favicon} />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossorigin
              />
              <link
                rel="preconnect"
                href="https://hubspot-cdn.uipath.com"
                crossOrigin
              />
              <link
                rel="preconnect"
                href="https://www.googletagmanager.com"
                crossOrigin
              />
            </Helmet>
            {!hideNormalLayout && !minimalHeader && <Header locale={lang} />}
            {!hideNormalLayout && minimalHeader && <MinHeader />}
            {children}
            {!hideNormalLayout && (
              <Footer
                minimalFooter={minimalFooter}
                staticData={footerJSON[lang]}
                locale={lang}
              />
            )}
          </ThemeProvider>
        </MuiThemeProvider>
      </div>
    )
  }
}

export default { Template }
