class UrlUtil {
  static getLocationHref = () => window.location.href

  static getUrlParameter = (parameter: string) => {
    parameter = parameter.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const url = UrlUtil.getLocationHref()
    const regex = new RegExp('[\\?|&]' + parameter.toLowerCase() + '=([^&#]*)')
    const results = regex.exec('?' + url.toLowerCase().split('?')[1])
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ''))
  }

  // static getUrlParameterFromUrl = (url: string, parameter: string) => {
  //   if (!url) {
  //     return ''
  //   }
  //   parameter = parameter.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  //   const regex = new RegExp('[\\?|&]' + parameter.toLowerCase() + '=([^&#]*)')
  //   const results = regex.exec('?' + url.toLowerCase().split('?')[1])
  //   return results === null
  //     ? ''
  //     : decodeURIComponent(results[1].replace(/\+/g, ''))
  // }

  static getSearchKeywords = (parameter: string) => {
    parameter = parameter.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const url = UrlUtil.getLocationHref()
    const regex = new RegExp('[\\?|&]' + parameter.toLowerCase() + '=([^&#]*)')
    const results = regex.exec('?' + url.toLowerCase().split('?')[1])
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/[+]/g, ' '))
  }

  static updateURLParameter = (uri: any, key: string, value: string) => {
    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    const separator = uri.indexOf('?') !== -1 ? '&' : '?'

    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
      return uri + separator + key + '=' + value
    }
  }

  static removeURLParameter = (uri: any, key: string) => {
    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    const separator = uri.indexOf('?') !== -1 ? '&' : '?'

    if (uri.match(re)) {
      return uri.replace(re, separator).replace(/[?&]$/, '')
    }
  }

  static getPageNumberForPagniation = (uri: any) => {
    //return uri;
    const parts = uri.split('/')
    if (parts[3] != undefined) return parts[3]
    return 1
  }
}

export default UrlUtil
