import styled from 'styled-components'

export const PaginationWrapper = styled.div`
  .pagination__numberedButtonContainer {
  }

  .pagination__numberedButton,
  .pagination__previousButton,
  .pagination__nextButton {
    position: relative;
    margin: 0 4px;
    display: inline-block;
    min-width: 48px;
    color: #273139;
    font-size: 14px;
    line-height: 48px;
    border: 2px solid #e3e3e3;
    box-sizing: border-box;
    text-align: center;
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    &:hover {
      background-color: #0067df;
      color: #fff;
      border: 2px solid #0067df;
    }

    &--active {
      background-color: #fff;
      color: ${(p) => p.theme.webMktPalette.text.primaryBlack};
      border: 2px solid ${(p) => p.theme.webMktPalette.background.dark};
    }
  }

  .pagination__ellipses {
    color: #343c4a;
    position: relative;
    margin: 0;
    display: inline-block;
    min-width: 42px;
    width: 42px;
    font-size: 14px;
    line-height: 48px;
    box-sizing: border-box;
    text-align: center;
    transition: all 0.2s ease-in-out;
    background-color: none;
  }
  .pagination__previousButton {
    ${(p) => p.theme.breakpoints.down('xs')} {
      padding-left: 16px;
      background-image: none;
    }
    padding-left: 32px;
    padding-right: 16px;

    display: inline-block;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease;

    background-image: url('https://www.uipath.com/hubfs/UiPath%20Resources/Assets/Pagination_Arrows_Left.svg');
    background-position: 10% center;
    background-repeat: no-repeat;

    &:hover {
      ${(p) => p.theme.breakpoints.down('xs')} {
        background-image: none;
      }
      background-image: url('https://www.uipath.com/hubfs/UiPath%20Resources/Assets/Pagination_Arrows_Left_White.svg');
    }

    &--hide {
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease;
    }
  }

  .pagination__nextButton {
    ${(p) => p.theme.breakpoints.down('xs')} {
      padding-right: 16px;
      background-image: none;
    }
    padding-left: 16px;
    padding-right: 32px;

    display: inline-block;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease;

    background-image: url('https://www.uipath.com/hubfs/UiPath%20Resources/Assets/Pagination_Arrow_Right.svg');
    background-position: 85% center;
    background-repeat: no-repeat;

    &:hover {
      ${(p) => p.theme.breakpoints.down('xs')} {
        background-image: none;
      }
      background-image: url('https://www.uipath.com/hubfs/UiPath%20Resources/Assets/Pagination_Arrows_Right_White.svg');
    }

    &--hide {
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease;
    }
  }
`
