import React, { useState, useEffect } from 'react'

//Styles
import * as style from './pagination'

type PaginationProps = {
  activePage: number
  handleClick: (val: number) => void
  numPagesBeforeEllipses: number
  numPages?: number
  numResultsPerPage?: number
  numResults?: number
  className?: string
  showPageNumbers?: boolean
  customNextText?: string
  customPreviousText?: string
  hrefForPage?: Function | null
}

/**
 * Takes in total number of pages to display
 * or uses total number of elements and divide by
 * number of elements to display per page.
 * Also takes in number of pages to display preceding the ellipses.
 *
 */
const Pagination = ({
  activePage,
  handleClick,
  numPages,
  numPagesBeforeEllipses = 5,
  numResults,
  numResultsPerPage,
  className,
  showPageNumbers = true,
  customNextText,
  customPreviousText,
  hrefForPage = null,
}: PaginationProps) => {
  const [isMobile, setIsMobile] = useState(false)
  const [renderingClientSide, setRenderingClientSide] = useState(false)
  // Get total pages
  let totalPages = 1
  if (numPages) {
    totalPages = numPages
  } else if (numResults && numResultsPerPage) {
    totalPages = Math.ceil(numResults / numResultsPerPage)
  }

  // Get number of pages before ellipses
  let pagesBeforeEllipses = numPagesBeforeEllipses || totalPages

  const numberList = Array.from(Array(totalPages), (x, index) => index + 1)

  // Event Listener
  const handleButtonClick = (e: any, val: number) => {
    e.preventDefault()
    if (val < 1 || val > totalPages || val === activePage) {
      return
    }

    if (typeof handleClick === 'function') {
      handleClick(val)
    }
  }

  const handleIsMobile = () => {
    setIsMobile(window.innerWidth <= 768)
  }

  const paginationDisplay = (currentPage: number, pageCount: number) => {
    var current = currentPage,
      last = pageCount,
      delta = 1,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l

    range.push(1)
    for (let i = current - delta; i <= current + delta; i++) {
      if (i >= left && i < right && i < last && i > 1) {
        range.push(i)
      }
    }
    range.push(last)

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1)
        } else if (i - l !== 1) {
          rangeWithDots.push('...')
        }
      }
      rangeWithDots.push(i)
      l = i
    }

    return rangeWithDots
  }

  useEffect(() => {
    handleIsMobile()
    setRenderingClientSide(true)
  }, [])

  const generateItems = () => {
    const items = paginationDisplay(activePage, totalPages).map(
      (item: number | string, n: number) => {
        const classes =
          item === activePage
            ? 'pagination__numberedButton pagination__numberedButton--active'
            : 'pagination__numberedButton'

        const href = hrefForPage ? hrefForPage(item) : ''

        if (item === '...') {
          return (
            <span className="pagination__ellipses" key={`Ellipses-${n}`}>
              ...
            </span>
          )
        } else {
          return (
            <a
              key={`page-${n}-${href}`}
              href={href}
              className={classes}
              onClick={
                hrefForPage
                  ? undefined
                  : (e: any) => {
                      handleButtonClick(e, item)
                    }
              }
            >
              {item}
            </a>
          )
        }
      }
    )

    return items
  }

  const coreRender = () => {
    return (
      <style.PaginationWrapper className={className}>
        <div className="pagination__numberedButtonContainer">
          {/* Don't show previous if on the first page */}
          {activePage !== 1 && (
            <a
              {...(hrefForPage ? { href: hrefForPage(activePage - 1) } : {})}
              className={`pagination__previousButton ${
                activePage === 1 ? 'pagination__previousButton--hide' : ''
              }`}
              onClick={
                hrefForPage
                  ? undefined
                  : (e: any) => {
                      handleButtonClick(e, activePage - 1)
                    }
              }
              rel="prev"
            >
              {/* if customPreviousText is set, the previous button text will not change, it will stay [< {customPreviousText}]' even on mobile. */}
              {customPreviousText
                ? customPreviousText
                : !isMobile
                ? 'Previous'
                : '<'}
            </a>
          )}

          {showPageNumbers && generateItems()}

          {/* Don't show next if on the last page */}
          {activePage !== totalPages && (
            <a
              {...(hrefForPage ? { href: hrefForPage(activePage + 1) } : {})}
              className={`pagination__nextButton ${
                activePage >= totalPages && numResults && numResultsPerPage
                  ? 'pagination__nextButton--hide'
                  : ''
              }`}
              onClick={
                hrefForPage
                  ? undefined
                  : (e: any) => {
                      handleButtonClick(e, activePage + 1)
                    }
              }
              rel="next"
            >
              {/* if customNextText is set, the next button text with not change, it will stay [{customNextText} >] even on mobile. */}
              {customNextText ? customNextText : !isMobile ? 'Next' : '>'}
            </a>
          )}
        </div>
      </style.PaginationWrapper>
    )
  }

  //we need to force this re-rendering when we're client side as the urls may have query parameters coming from window.location.search
  if (renderingClientSide) {
    return <div>{coreRender()}</div>
  } else {
    return coreRender()
  }
}

export default Pagination
